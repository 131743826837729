<template>
  <div class="courier">
    <Header title="Данные курьера" isCourier />

    <div class="wrapper">
      <div class="courier__detail">
        <div class="container">
          <ul>
            <li class="courier__detail-item">
              <p class="courier__detail-left">ФИО</p>
              <input class="courier__detail-right courier__detail-count" v-model="name">
            </li>  
            <li class="courier__detail-item">
              <p class="courier__detail-left">День Рождения</p>
              <input class="courier__detail-right courier__detail-count" v-model="birthdate" v-mask="{mask: '99.99.9999'}">
            </li> 
            <li class="courier__detail-item">
              <p class="courier__detail-left">Телефон</p>
              <input class="courier__detail-right courier__detail-count" v-model="phone" v-mask="{mask: '9999999999'}">
            </li> 
            <li class="courier__detail-item">
              <p class="courier__detail-left">Код доступа</p>
              <p class="courier__detail-right courier__detail-count">
                <span class="courier__code-new" @click="getNewCode">Новый</span>
                <span class="courier__code">{{ this.courier_card.КодДоступа }}</span>
              </p>
            </li>   
            <li class="courier__detail-item">
              <p class="courier__detail-left">Уволен</p>
              <div class="courier__detail-right courier__detail-count">
                <ToggleButton 
                  v-model="fired"
                  :labels="{checked: 'Да', unchecked: 'Нет'}" 
                  :color="{checked: '#cc8588', unchecked: '#75c791', disabled: '#CCCCCC'}"
                />
              </div>
            </li>            
          </ul>  
        </div>  
      </div>
      <div class="payment">
        <div class="container">
          <div class="payment__buttons">
            <router-link to="/couriers" tag="button" class="payment__submit">
              Отмена
            </router-link>
            <button class="payment__submit" @click="editCourier">
              Сохранить
            </button>
          </div>   
        </div>
      </div>    
    </div>

    <Loader v-if="isLoading" />

    <popup :is-opened="openPopup" @close-popup="openPopup = false" :title="popupTitle" :text="popupText"></popup>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Popup from '@/components/Popup.vue'
import Vue from 'vue'
import Loader from '@/components/Loader.vue'
import moment from 'moment'
import { ToggleButton } from 'vue-js-toggle-button'

Vue.prototype.moment = moment

export default {
  name: 'Courier',
  components: {
    Header,
    Popup,
    ToggleButton,
    Loader
  },
  data () {
    return {
      isLoading: false,
      fields: {},
      openPopup: false,
			popupTitle: '',
			popupText: '',
      nameSet: '',
      birthdateSet: '',
      phoneSet: '',
      firedSet: false
    }
  },
  computed: {
    courier_card () {
      return this.$store.state.courier_card ? this.$store.state.courier_card : {}
    },
    name: {
      get () {
        if (this.nameSet === '') {
          return this.courier_card.Наименование
        } else
        return this.nameSet
      },
      set (value) {
        this.nameSet = value 
      }
    },
    birthdate: {
      get () {
        if (this.birthdateSet === '' && this.courier_card.ДатаРождения) {
          return moment(this.courier_card.ДатаРождения).format("DD.MM.YYYY")
        } else
        return this.birthdateSet
      },
      set (value) {
        this.birthdateSet = value 
      }
    },
    phone: {
      get () {
        if (this.phoneSet === '') {
          return this.courier_card.Телефон
        } else
        return this.phoneSet
      },
      set (value) {
        this.phoneSet = value 
      }
    },
    fired: {
      get () {
        return this.courier_card.Уволен ? this.courier_card.Уволен : false
      },
      set (value) {
        this.firedSet = value 
      }
    },
  },
  filters: {
    time: value => {
      return !value ? '' : value.split('T')[1].slice(0, -3)
    },
    date: value => {
      return !value ? '' : value.split('T')[1].slice(0, -3)
    },
    number: value => {
      return !value ? '' : value.split('-')[1]
    }
  },
  created () {
    if (!this.$store.state.isAdmin) {
      this.$router.push('/')
    }
    this.$root.$on('popup', this.popupHandler) 
  },
  methods: {
    popupHandler ({ title,text }) {
			this.popupTitle = title
			this.popupText = text
			this.openPopup = true
    },
    refresh () {
      this.$store.dispatch('GET_COURIER', this.$store.state.courier_id)
    },
    getNewCode () {
      this.isLoading = true
      this.$store.dispatch('GET_NEW_CODE', this.$store.state.courier_id)
        .then(
          (response) => {
            this.isLoading = false
            if (response.status === 400) {
              this.$root.$emit('popup', {title: 'Ошибка', text: response.data.ОписаниеОшибки })
            }		
          },
          (err) => {
            this.isLoading = false
            this.$root.$emit('popup', {title: 'Ошибка', text: 'Ошибка загрузки кода' })
            throw new Error(err)
          }
        )
        .catch (() => {
          this.isLoading = false
          this.$root.$emit('popup', {title: 'Ошибка', text: 'Ошибка загрузки кода'})
        })
        
    },
    editCourier () {
      this.isLoading = true
      this.fields = {
        UUID: this.$store.state.courier_id,
        Name: this.name,
        Birthdate: this.birthdate,
        Fired: this.fired,
        Phone: this.phone
      }

      this.$store.dispatch('EDIT_COURIER', this.fields) 
        .then(
          (response) => {
            this.isLoading = false
            if (response.status === 200) {
              this.$router.push('/couriers')
            } else if (response.status === 400) {
              this.$root.$emit('popup', {title: 'Ошибка', text: response.data.ОписаниеОшибки })
            }		
          },
          (err) => {
            this.isLoading = false
            this.$root.$emit('popup', {title: 'Ошибка', text: 'Ошибка загрузки' })
            throw new Error(err)
          }
        )
        .catch (() => {
          this.isLoading = false
          this.$root.$emit('popup', {title: 'Ошибка', text: 'Ошибка загрузки'})
        })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .header__info {
  font-size: 16px;
  white-space: nowrap;
}
.wrapper {
  width: 100%;
  margin: 0px auto;
  background-color: #fff;
}
.container {
  margin: 0 10px;
}
.courier {
  min-height: 100vh;
  background-color: #f2f2f2;
  &__info {
    background-color: #f2f2f2;
    &-item {
      padding: 12px 10px;
      border-bottom: 1px solid #cacaca;
      &--nested {
        display: flex;
        justify-content: space-between;
        .order__info-inner + .order__info-inner {
          margin-left: 5px;
        }
      }
      &--timer {
        text-align: center;
      }
    }
    &-inner {
      display: flex;
      flex-direction: column;
    }
    &-header {
      color: #cacaca;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 4px;
    }
    &-value {
      font-size: 14px;
      line-height: 16px;
      &--map {
        text-decoration: underline;
        text-decoration-color: rgb(170, 170, 170);
      }
    }
  }
  &__detail {
    background-color: #fff;
    font-size: 14px;
    &-item {
      padding: 12px 10px 10px;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:last-of-type {
        border-bottom: 1px solid #000;
      }  
    }
    &-right {
      width: 60%;
      text-align: right;
      border-color: #b6b6b6;
      border-width: 1px;
      padding: 1px 3px;
      font-size: 14px;
      line-height: 1;
      font-family: $font-base;
      outline-width: 1px!important;
      border-radius: 3px;
    }
    &-left {
      flex: 1;
      margin-right: 10px;
      color: #7e7e7e;
    }
    &-gray {
      color: #7e7e7e;
    }
    &-overall {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 10px 10px;
      border-top: 1px solid #000;   
      position: relative;
      top: -1px;   
    }
    &-sum {
      text-align: right;
      width: calc(100% - 86px);
      margin-right: 26px;
    }
  }
  &__code {
    margin-left: 12px;
    &-new {
      color: #75c791;
      cursor: pointer;
    }
  }
}
.payment {
  background-color: #f2f2f2;
  &__items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  &__item {
    width: calc(50% - 10px);
    &-check {
      margin-bottom: 16px;
      margin-left: 10px;
      white-space: nowrap;
    }
    &-head {
      padding: 14px 10px 13px;
      color: #7e7e7e;
      margin-bottom: 17px;
      font-size: 14px;
      border-bottom: 1px solid #cacaca;
    }
  }
  &__email {
    margin: 0 10px 24px;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__button {
    margin: 0 10px;
    padding-bottom: 25px;
  }
  &__submit {
    width: 47%;
    color: #000;
    font-family: $font-base;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    padding: 12px 0 11px;
    border-radius: 50px;
    background-color: $main;
    cursor: pointer;
    margin-top: 24px;
    &:active {
      transform: scale(0.98);
    }
  }
}
.email__input {
  background-color: #fff;
  width: 100%;
  padding: 12px 20px 14px;
  margin: 0;
  outline: none;
  border: 1px solid #cacaca;
  border-radius: 50px;
  color: #000;
  font-size: 16px;
  font-family: $font-base;
}
.error-input {
  box-shadow: 0px 0px 10px $red;
}
.error-radio::before {
  box-shadow: 0px 0px 7px $red;
}
</style>
